@font-face {
  font-family: VodafoneLight;
  src: url(/vodafone-light.woff);
  font-weight: lighter;
}

@font-face {
  font-family: VodafoneRegular;
  src: url(/vodafone-regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: VodafoneBold;
  src: url(/VodafoneBold.woff);
  font-weight: normal;
}

html {
  font-size: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: VodafoneRegular;
  font-size: 1rem;
  line-height: 1.5;
  height: 100%;
}

button:disabled {
  opacity: 0.5;
}

#root{
	height: 100%;
}
  
@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



.paymentModal {
  /* position: absolute; */
  /* z-index: 9999999999; */
  background-color: #343434;
  color: white;
  width: 100%;
  min-height: 100vh;
  /* padding: 5vh 0; */
  overflow-x: hidden;
}

.textCenter{
  text-align: center;
}

.buttonContainer {
  /* position: sticky;
  left: 10%;
  bottom: 16px; */
  /* align-self: flex-end; */
  width: 80%;
  transition: .3s;
}

@media only screen and (min-height: 650px) {
  .buttonContainer {
    margin-top: 32px;
  }
}

@media only screen and (min-height: 675px) {
  .buttonContainer {
    margin-top: 48px;
  }
}

@media only screen and (min-height: 700px) {
  .buttonContainer {
    margin-top: 64px;
  }
}

/* not in mobile */
@media only screen and (min-width: 600px) {
  .buttonContainer {
    position: unset;
    display: flex;
    justify-content: space-around;
    margin-top: 3vh;
    flex-direction: row-reverse;
    padding-bottom: 15px;
  }
}


@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');